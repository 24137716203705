/*
 * Search Toggle
 *
 * runs the functionality of clicking a search icon and a search bar appears
 */

function searchBar() {

    // Search Toggle
    $('.js-header-search-toggle').on('click', function (e) {
        e.preventDefault();
        $(".js-header").toggleClass('l-header--search-is-active');
        $(".c-search-bar").toggleClass('c-search-bar--active');
        $("body").toggleClass('h-scroll-lock');

        setTimeout(function () {
            $('.c-search-bar__search-field').focus();
        }, 600);
    });
}

export default searchBar;

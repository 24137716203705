/* eslint-disable */

/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-smil-setclasses !*/
!function(e,n,s){function o(e,n){return typeof e===n}function a(){var e,n,s,a,t,r,f;for(var c in l)if(l.hasOwnProperty(c)){if(e=[],n=l[c],n.name&&(e.push(n.name.toLowerCase()),n.options&&n.options.aliases&&n.options.aliases.length))for(s=0;s<n.options.aliases.length;s++)e.push(n.options.aliases[s].toLowerCase());for(a=o(n.fn,"function")?n.fn():n.fn,t=0;t<e.length;t++)r=e[t],f=r.split("."),1===f.length?Modernizr[f[0]]=a:(!Modernizr[f[0]]||Modernizr[f[0]]instanceof Boolean||(Modernizr[f[0]]=new Boolean(Modernizr[f[0]])),Modernizr[f[0]][f[1]]=a),i.push((a?"":"no-")+f.join("-"))}}function t(e){var n=f.className,s=Modernizr._config.classPrefix||"";if(c&&(n=n.baseVal),Modernizr._config.enableJSClass){var o=new RegExp("(^|\\s)"+s+"no-js(\\s|$)");n=n.replace(o,"$1"+s+"js$2")}Modernizr._config.enableClasses&&(n+=" "+s+e.join(" "+s),c?f.className.baseVal=n:f.className=n)}var i=[],l=[],r={_version:"3.6.0",_config:{classPrefix:"",enableClasses:!0,enableJSClass:!0,usePrefixes:!0},_q:[],on:function(e,n){var s=this;setTimeout(function(){n(s[e])},0)},addTest:function(e,n,s){l.push({name:e,fn:n,options:s})},addAsyncTest:function(e){l.push({name:null,fn:e})}},Modernizr=function(){};Modernizr.prototype=r,Modernizr=new Modernizr;var f=n.documentElement,c="svg"===f.nodeName.toLowerCase(),u={}.toString;Modernizr.addTest("smil",function(){return!!n.createElementNS&&/SVGAnimate/.test(u.call(n.createElementNS("http://www.w3.org/2000/svg","animate")))}),a(),t(i),delete r.addTest,delete r.addAsyncTest;for(var d=0;d<Modernizr._q.length;d++)Modernizr._q[d]();e.Modernizr=Modernizr}(window,document);

import jQuery from 'jquery';
import '../style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';

import axios from 'axios'

import scrollToTop from '../components/objects/scroll-to-top/scroll-to-top';
import searchBar from '../components/objects/search/search-bar/search-bar';
import navMob from '../components/objects/nav/nav-mobile/nav-mobile';
import citySelector from '../components/objects/city-selector/city-selector';
import introBlock from '../components/intro-block/intro-block';
import introSlider from '../components/slides/intro/intro';
import blastSlider from '../components/slides/blasts/blasts'
import giveAwaySlider from '../components/slides/giveaway/giveaway';
import eNewsLetters from '../components/slides/e-newsletters/e-newsletters';
import publishingSlider from '../components/slides/publishing-slider/publishing-slider';
import multiChannelMarketingSlider from '../components/slides/multichannel-marketing/multichannel-marketing';
import textImageSlider from '../components/slides/text-image-slider/text-image-slider';
import magazineSpreadSlider from '../components/slides/magazine-spread-slider/magazine-spread-slider';
import pwSpreadSlider from '../components/slides/pw-show-daily/pw-show-daily';
import pwPodcastsSlider from '../components/slides/podcasts/podcasts';
import socialShare from '../components/objects/social/social-share/social-share';
import gallery from '../components/blocks-zone-yellow/gallery/gallery';
import tabs from '../components/objects/blog-overview-tabs/blog-overview-tabs';
import membershipCard from '../components/objects/cards/membership/membership';
import fullPageScroll from '../components/slides/slide';
import audienceGraphs from '../components/slides/audience-graphs/audience-graphs';
import ctaSide from '../components/objects/cta/cta-side/cta-side';
import {
    hamburger,
    hamburgerClose
} from '../components/objects/nav/hamburger/hamburger';

import scrollToAnchor from './custom/scroll/scroll-to-anchor';
import parallaxScroll from './custom/parallax/parallaxscroll';
import headRoom from './custom/headroom/headroom';

import "magnific-popup";

import './core/core';

/** Responsive Images Polyfill */
import 'picturefill';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home
  /** About Us page, note the change from about-us to aboutUs. */
});

// functions below are only run if the component exists (verified through a "data-module" in the html)
// in the ajax container or page. Barba.js instantiates the functions below on container reload as well.

// Place in alphabetical order
const app = {
    citySelector,
    ctaSide,
    gallery,
    hamburger,
    audienceGraphs,
    headRoom,
    introBlock,
    eNewsLetters,
    membershipCard,
    searchBar,
    pwSpreadSlider,
    pwPodcastsSlider,
    fullPageScroll,
    scrollToTop,
    multiChannelMarketingSlider,
    introSlider,
    giveAwaySlider,
    blastSlider,
    magazineSpreadSlider,
    publishingSlider,
    textImageSlider,
    socialShare,
    tabs
};
app.instantiate = function (elem) {
    const $this = $(elem);
    const module = $this.attr('data-module');

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in app) {
        if ($this.attr('data-initialized') === "true") return;
        new app[module](elem);
        $this.attr('data-initialized', true);
    } else {
        throw 'Module \'' + module + '\' not found';
    }
};

jQuery(document).ready(function () {
    routes.loadEvents()

    // Inline popups
    $('#inline-popups').magnificPopup({
        delegate: 'a',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });


    // Image popups
    $('.js-gallery').each(function() {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
                beforeOpen: function() {
                    // just a hack that adds mfp-anim class to markup
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
            closeOnContentClick: true,
            midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
        });
    });

    scrollToAnchor($);
    navMob($);

    // $('.js-appie').click(function(e){
    //     e.preventDefault()
    //     console.log("ds")
    //     fancySquaresLoadMore();
    // });

    // function fancySquaresLoadMore(argument) {
    //
    //
    //     console.log("ds2")
    //
    //     let fancySquaresRestUrl = $('body').data('rest'),
    //         $loadMoreButton = $('#data-parameters'),
    //         $archiveLayout = $('[data-js="blog"]'),
    //         loadMoreButtonID = '#data-parameters',
    //         $loader = $('#loader');
    //
    //     console.log(fancySquaresRestUrl)
    //     // start click
    //     $archiveLayout.on('click',loadMoreButtonID,function(){
    //
    //
    //         let $this = $(this),
    //             pageCount = $(this).attr('data-pageCount'),
    //             nextPage = parseInt($(this).attr('data-page')) + 1;
    //
    //         $loader.fadeIn();
    //         $loadMoreButton.fadeOut();
    //
    //         let form_data = new FormData,
    //             dataValues = $(this).data();
    //         for (var key in dataValues) {
    //             // all_values.push([key, data[key]]);
    //             // key[pagecount] = pageCount;
    //             if (key === 'pagecount'){
    //
    //                 dataValues[key] = Number(pageCount);
    //                 form_data.append(key, dataValues[key]);
    //                 // console.log(key, data[Number(pageCount)+1]);
    //             } else {
    //                 form_data.append(key, dataValues[key]);
    //             }
    //             // console.log(key, dataValues[key]);
    //         }
    //
    //         axios.post(ajaxurl, form_data)
    //             .then(function(response){
    //                 // console.log(response);
    //                 // console.log(response.data);
    //
    //                 let postData = response.data;
    //
    //                 if(postData === 'nomore'){
    //                     $loadMoreButton.fadeOut();
    //                 } else {
    //                     for(var z = 0; z < postData.length; z++){
    //                         $('[data-js="append-content"]').append(postData[z].data);
    //                         $this.attr('data-pagecount', Number(pageCount)+1);
    //                         // console.log(postData[z]);
    //                     }
    //
    //                     $loadMoreButton.fadeIn();
    //                 }
    //
    //
    //                 $loader.fadeOut();
    //             })
    //             .catch(function (response) {
    //                 //handle error
    //                 console.log(response);
    //             });
    //
    //
    //
    //     });
    //     // end click
    // }

    // Component functions that should only run if the component exists in the ajax container or page
    $('[data-module]').each(function () {
        app.instantiate(this);
    });
});

jQuery(window).scroll(function () {
    parallaxScroll(jQuery);
});

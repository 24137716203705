function ctaSide() {
    $( '.c-single-location__sidebar-schedule-btn' ).click( function( e ) {
        e.preventDefault();

        $( '.l-sidebar--location' ).slideDown();
    });

    $( '.c-cta-side__close-btn' ).click( function( e ) {
        e.preventDefault();

        $( '.l-sidebar--location' ).slideUp();
    });
}

export default ctaSide;
/*
 * Sliders
 *
 * Calling the Slick Slider
 */
import '../../../js/custom/slick/slick';

var slider_dots;
const defaultSettings = {
    arrows: true,
    infinite: true,
    pauseOnHover: true,
    autoplay: false
};

export default () => {

    $('.js-slider-multichannel-1').each(function () {

        const $child_slider = $(this);
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: true,
            autoplaySpeed: 10000,
            speed: 650,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: 0,
            center: true,
            autoplay: false,
            centerMode: true,
            asNavFor: '.js-slider-multichannel-2',
            infinite: true,
            rows: 0,
            prevArrow: $child_slider.parent().find('.js-slider-prev'),
            nextArrow: $child_slider.parent().find('.js-slider-next'),
            appendDots: $child_slider.parent().next().find('.c-slide__dots-wrapper-inner'),
            customPaging: function (slider, i) {
                var thumb = $(slider.$slides[i]).attr('data-thumb');
                return '<span class="c-slide__pagination-text">' + thumb + '</span>';
            }




            // responsive: [{
            //     breakpoint: 900,
            //     settings: {
            //         slidesToShow: 1,
            //         slidesToScroll: 1,
            //         infinite: true,
            //         dots: false
            //     }
            // }]
        });

        $('li[data-slide]').click(function(e) {
            e.preventDefault();
            var slideno = $(this).data('slide');
            $('.js-slider-multichannel-1').slick('slickGoTo', slideno - 1);
        });

        $child_slider.not('.slick-initialized').slick(child_settings);
    });

    $('.js-slider-multichannel-2').each(function () {

        const $child_sliderx = $(this);
        const $next_slider = $child_sliderx.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: false,
            autoplaySpeed: 10000,
            speed: 650,
            slidesToShow: 1,
            autoplay: false,
            slidesToScroll: 1,
            centerPadding: 0,
            center: true,
            asNavFor: '.js-slider-multichannel-1',
            centerMode: true,
            infinite: true,
            rows: 0,
        });
        $child_sliderx.not('.slick-initialized').slick(child_settings);
    });

    $('.js-slider-multichannel-3').each(function () {

        const $child_sliderx = $(this);
        const $next_slider = $child_sliderx.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: false,
            autoplaySpeed: 10000,
            speed: 650,
            slidesToShow: 1,
            autoplay: false,
            slidesToScroll: 1,
            centerPadding: 0,
            center: true,
            asNavFor: '.js-slider-multichannel-1',
            centerMode: true,
            infinite: true,
            rows: 0,
        });
        $child_sliderx.not('.slick-initialized').slick(child_settings);
    });
}

/*
 * Sliders
 *
 * Calling the Slick Slider
 */

import '../../../js/custom/slick/slick';

var slider_dots;
const defaultSettings = {
    arrows: true,
    infinite: true,
    pauseOnHover: true
};

export default () => {

    $('.js-slider-text-image').each(function () {

        const $child_slider = $(this);
        slider_dots = Boolean($child_slider.attr('data-dots'));
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: slider_dots,
            autoplaySpeed: 6000,
            speed: 650,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: 0,
            center: true,
            centerMode: true,
            infinite: true,
            rows: 0,
            asNavFor: '.js-slider-text-image-2',
            prevArrow: $child_slider.parent().find('.js-slider-prev'),
            nextArrow: $child_slider.parent().find('.js-slider-next'),
            // responsive: [{
            //     breakpoint: 900,
            //     settings: {
            //         slidesToShow: 1,
            //         slidesToScroll: 1,
            //         infinite: true,
            //         dots: false
            //     }
            // }]
        });

        $child_slider.not('.slick-initialized').slick(child_settings);
    });

    $('.js-slider-text-image-2').each(function () {

        const $child_slider = $(this);
        slider_dots = Boolean($child_slider.attr('data-dots'));
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: slider_dots,
            speed: 650,
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: 0,
            center: true,
            asNavFor: '.js-slider-text-image',
            centerMode: true,
            infinite: true,
            rows: 0,
        });
        $child_slider.not('.slick-initialized').slick(child_settings);
    });
}

/*
 * Sliders
 *
 * Calling the Slick Slider
 */

import '../../../js/custom/slick/slick';

var slider_dots;
const defaultSettings = {
    arrows: true,
    infinite: true,
    pauseOnHover: true
};

export default () => {

    $('.js-slider-pw-daily').each(function () {

        const $child_slider = $(this);
        slider_dots = Boolean($child_slider.attr('data-dots'));
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: slider_dots,
            speed: 450,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: 0,
            center: true,
            asNavFor: '.js-videos-slider',
            centerMode: true,
            infinite: true,
            rows: 0,
            prevArrow: $child_slider.parent().find('.js-slider-prev'),
            nextArrow: $child_slider.parent().find('.js-slider-next'),
        });

        $child_slider.on({
            beforeChange: function (event, slick, currentSlide, nextSlide) {
                $('.c-slide__column-pw-show-extra-2').removeClass('slide' + (currentSlide + 1) + '-current').addClass('slide' + (nextSlide + 1) + '-current')
                $('.c-slide__column-pw-show-extra-2').removeClass('c-slide__column-pw-show-extra');
            }
        });

        $child_slider.not('.slick-initialized').slick(child_settings);

        $('.js-pw-show-button-1').on('click', function (e) {
            $child_slider.slick('slickGoTo', 0)
        });
        $('.js-pw-show-button-2').on('click', function (e) {
            $child_slider.slick('slickGoTo', 1)
        });
        $('.js-pw-show-button-3').on('click', function (e) {
            $child_slider.slick('slickGoTo', 2)
        });
        $('.js-pw-show-button-4').on('click', function (e) {
            $child_slider.slick('slickGoTo', 3)
        });
        $('.js-pw-show-button-5').on('click', function (e) {
            $child_slider.slick('slickGoTo', 4)
        });
    });

    $('.js-videos-slider').each(function () {
        const $child_slider = $(this);
        slider_dots = Boolean($child_slider.attr('data-dots'));
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: slider_dots,
            autoplaySpeed: 1600,
            speed: 600,
            cssEase: 'ease-out',
            useTransform: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.js-slider-pw-daily',
            autoplay: false,
            centerPadding: 0,
            center: true,
            centerMode: true,
            arrows: false,
            infinite: true,
            rows: 0,
        });
        $child_slider.not('.slick-initialized').slick(child_settings);

        console.log("slider title repeater")
    });
}

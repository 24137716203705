/*
 * Sliders
 *
 * Calling the Slick Slider
 */

import '../../../js/custom/slick/slick';

var slider_dots;
const defaultSettings = {
    arrows: true,
    infinite: true,
    pauseOnHover: true,
    autoplay: true
};

export default () => {

    $('.js-slider-giveaway').each(function () {

        const $child_slider = $(this);
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: false,
            autoplaySpeed: 10000,
            speed: 650,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: 0,
            center: true,
            centerMode: true,
            infinite: true,
            rows: 0,
            prevArrow: $child_slider.parent().find('.js-slider-give-prev'),
            nextArrow: $child_slider.parent().find('.js-slider-give-next'),
            // responsive: [{
            //     breakpoint: 900,
            //     settings: {
            //         slidesToShow: 1,
            //         slidesToScroll: 1,
            //         infinite: true,
            //         dots: false
            //     }
            // }]
        });

        $child_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            console.log(nextSlide);
            $('.js-img-giveaways').removeClass('c-slide__scroll-anim-giveaway');
        });

        $('.js-img-giveaways').on('click', function (e) {
            $('.js-img-giveaways').addClass('c-slide__scroll-anim-giveaway');
        });

        console.log($child_slider.parent().next())
        $child_slider.not('.slick-initialized').slick(child_settings);
    });
}

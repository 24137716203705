function citySelector() {

    $('.js-city-selector').click(function () {
        console.log("ds")
        event.preventDefault();
        $(this).find('.c-city-selector__arrow').toggleClass('c-city-selector__arrow--active');
        $(this).parent().next('.c-city-selector__list').toggleClass('c-city-selector__list--open');
    });
}

export default citySelector;

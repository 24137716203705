function blogOverviewTabs() {

// tabbed content
// http://www.entheosweb.com/tutorials/css/tabs.asp
    $(".c-tabs__content").hide();
    $(".c-tabs__content:first").show();

    /* if in tab mode */
    $(".c-tabs__toggle").click(function () {

        $(".c-tabs__content").hide();
        var activeTab = $(this).attr("rel");
        $("#" + activeTab).fadeIn();

        $(".c-tabs__toggle").removeClass("c-tabs__toggle--active");
        $(this).addClass("c-tabs__toggle--active");

        $(".c-tabs__drawer-toggle").removeClass("c-tabs__drawer-toggle--active");
        $(".c-tabs__drawer-toggle[rel^='" + activeTab + "']").addClass("c-tabs__drawer-toggle--active");

    });
    /* if in drawer mode */
    $(".c-tabs__drawer-toggle").click(function () {

        $(".c-tabs__content").hide();
        var d_activeTab = $(this).attr("rel");
        $("#" + d_activeTab).fadeIn();

        $(".c-tabs__drawer-toggle").removeClass("c-tabs__drawer-toggle--active");
        $(this).addClass("c-tabs__drawer-toggle--active");

        $(".c-tabs__toggle").removeClass("c-tabs__toggle--active");
        $(".c-tabs__toggle[rel^='" + d_activeTab + "']").addClass("c-tabs__toggle--active");
    });

    $(".c-tabs__select").on('change', function () {
        var selectedOptionLocation = $('option:selected', this).data('location');

        window.location = selectedOptionLocation;
    });
}

export default blogOverviewTabs;


/* Extra class "tab_last"
   to add border to right side
   of last tab */
// $('.c-tabs__toggle').last().addClass("tab_last");

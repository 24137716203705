/*
 * Sliders
 *
 * Calling the Slick Slider
 */

import '../../../js/custom/slick/slick';

var slider_dots;
const defaultSettings = {
    arrows: true,
    infinite: true,
    pauseOnHover: true
};

export default () => {

    $('.js-slider-newsletters').each(function () {
        const $child_slider = $(this);
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: true,
            autoplaySpeed: 999000,
            speed: 350,
            center: true,
            autoplay: false,
            cssEase: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
            centerPadding: '0',
            slidesToShow: 1,
            infinite: true,
            slidesToScroll: 1,
            asNavFor: '.js-slider-newsletters-2',
            rows: 0,
            centerMode: true,
            adaptiveHeight: true,
            appendDots: $child_slider.parent().parent().next().find('.c-slide__dots-wrapper-inner'),
            customPaging: function (slider, i) {
                var thumb = $(slider.$slides[i]).attr('data-thumb');
                return '<span class="c-slide__pagination-text">' + thumb + '</span>';
            }
        });
        $child_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            console.log(nextSlide);
            $('.js-img-newsletters').removeClass('c-slide__scroll-anim');
            setTimeout(function(){
                $('.js-img-newsletters').addClass('c-slide__scroll-anim');
            }, 200);
        });
        $child_slider.not('.slick-initialized').slick(child_settings);
    });

    $('.js-img-newsletters').on('click', function (e) {
        $('.js-img-newsletters').addClass('c-slide__scroll-anim');
    });

    $('.js-slider-newsletters-2').each(function () {

        const $child_slider = $(this);
        slider_dots = Boolean($child_slider.attr('data-dots'));
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: slider_dots,
            autoplaySpeed: 10000,
            speed: 650,
            slidesToShow: 1,
            autoplay: false,
            fade: true,
            slidesToScroll: 1,
            centerPadding: 0,
            center: true,
            asNavFor: '.js-slider-newsletters',
            centerMode: true,
            infinite: true,
            rows: 0,
        });
        $child_slider.not('.slick-initialized').slick(child_settings);
    });
}

/*
 * Sliders
 *
 * Calling the Slick Slider
 */

import '../../../js/custom/slick/slick';

var slider_dots;
const defaultSettings = {
    arrows: true,
    infinite: true,
    pauseOnHover: true,
    autoplay: true
};

export default () => {

    $('.js-slider-title-repeater').each(function () {
        const $child_slider = $(this);
        slider_dots = Boolean($child_slider.attr('data-dots'));
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: slider_dots,
            autoplaySpeed: 1600,
            speed: 600,
            cssEase: 'ease-out',
            useTransform: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            vertical: true,
            autoplay: true,
            centerPadding: 0,
            center: true,
            centerMode: true,
            arrows: false,
            infinite: true,
            rows: 0,
        });
        $child_slider.not('.slick-initialized').slick(child_settings);

        console.log("slider title repeater")
    });
}

/*
 * audienceGraph
 *
 * runs the funcitonality of the scroll to top button that appears at the bottom right of the website
 */
/* eslint-disable */

function audienceGraphs() {
    $('.js-audience-1').on('click', function (e) {
        e.preventDefault()

        $('.c-slide__graphs--2').removeClass('c-slide__graphs--active');
        $('.c-slide__graphs--3').removeClass('c-slide__graphs--active');
        $('.c-slide__graphs--1').addClass('c-slide__graphs--active');
        $('.c-slide__item--1').addClass('c-slide__item--active');
        $('.c-slide__item--2').removeClass('c-slide__item--active');
        $('.c-slide__item--3').removeClass('c-slide__item--active');
    });
    $('.js-audience-2').on('click', function (e) {
        e.preventDefault()
        $('.c-slide__graphs--3').removeClass('c-slide__graphs--active');
        $('.c-slide__graphs--1').removeClass('c-slide__graphs--active');
        $('.c-slide__graphs--2').addClass('c-slide__graphs--active');
        $('.c-slide__item--2').addClass('c-slide__item--active');
        $('.c-slide__item--1').removeClass('c-slide__item--active');
        $('.c-slide__item--3').removeClass('c-slide__item--active');
    });
    $('.js-audience-3').on('click', function (e) {
        e.preventDefault()
        $('.c-slide__graphs--2').removeClass('c-slide__graphs--active');
        $('.c-slide__graphs--1').removeClass('c-slide__graphs--active');
        $('.c-slide__graphs--3').addClass('c-slide__graphs--active');
        $('.c-slide__item--3').addClass('c-slide__item--active');
        $('.c-slide__item--1').removeClass('c-slide__item--active');
        $('.c-slide__item--2').removeClass('c-slide__item--active');
    });
}

export default audienceGraphs;

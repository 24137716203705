function membershipCard() {
    /* --- Show membership features on Learn More click --- */
    const readMoreAnchor = $( '.c-card-membership__learn-more' );

    readMoreAnchor.on( 'click', function ( e ) {
        e.preventDefault();

        const footerSelectedItemContent = $( this ).next( '.c-card-membership__dropdown' );

        $( this ).children( 'i' ).toggleClass( 'fa-caret-down fa-caret-up' );

        footerSelectedItemContent.slideToggle( 400 );
    } );
    /* --- End of Learn More logic --- */
}

export default membershipCard;
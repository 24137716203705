import $ from 'jquery';
window.$ = window.jQuery = $;
import '../../js/custom/slick/slick';
import '../../js/custom/fullpage/scrolloverflow.min.js';
import '../../js/custom/fullpage/fullpage.scrollOverflowReset.min.js';
import '../../js/custom/fullpage/fullpage.parallax.min.js';
// import fullpage from '../../js/custom/fullpage/ds.js';
import fullpage from "fullpage.js";
// import '../../js/custom/fullpage/fullpage.extensions.min.js';

export default () => {
    jQuery(document).ready(function () {
        new fullpage("#fullpage", {
            easing: 'easeInOutCubic',
            easingcss3: 'ease',
            parallax: true,
            parallaxKey: '9DCF8573-EA8E4C6D-BA5490D2-D519C127',
            licenseKey: 'ED60277E-CDBC48B4-9BC6F3CA-96D3A40B',
            // anchors: ['slide-1', 'slide-2', 'slide-3', 'slide-4', 'slide-5', 'slide-6', 'slide-7'],
            navigation: true,
            navigationPosition: 'left',
            navigationTooltips: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
            controlArrows: true,
            scrollOverflow: true,
            scrollOverflowReset: true,
            scrollOverflowResetKey: 'C3225272-509D4BA6-82B8DC4C-8FBC2D0B',
            scrollOverflowEndPrevent: { delay: 500, reversal: false },
            // css3: false, // otherwise background-attachment wouldn't work
            parallaxOptions: {
                type: 'reveal',
                percentage: 62,
                property: 'translate'
            },
            // verticalCentered: false,
            slidesNavigation: true,
            scrollingSpeed: 500,
            lazyLoading: true,
            afterLoad: function(anchorLink, index){
                if ($(".c-slide--publishing-slider").hasClass("active")) {
                    $('.js-slider-publishing').slick('slickSetOption', 'autoplay', true, true);
                }
                if ($(".c-slide--text-image-slider").hasClass("active")) {
                    $('.js-slider-text-image').slick('slickSetOption', 'autoplay', true, true);
                }
                if ($(".c-slide--magazine-spread-slider").hasClass("active")) {
                    $('.js-slider-magazine-spread').slick('slickSetOption', 'autoplay', true, true);
                }
                if ($(".c-slide--e-newsletters").hasClass("active")) {
                    $('.c-slide__scroll-anim--start-ready').addClass("c-slide__scroll-anim");
                }
                if ($(".c-slide--blasts").hasClass("active")) {
                    $('.js-slider-blasts').slick('slickSetOption', 'autoplay', true, true);
                }
                if ($(".c-slide--multiple-channels-slider").hasClass("active")) {
                    if ($(window).width() > 992) {
                        var type = 1, //circle type - 1 whole, 0.5 half, 0.25 quarter
                            radius = '11em', //distance from center
                            start = -90, //shift start from 0
                            $elements = $('.c-slide__nav-item:not(:first-child)'),
                            numberOfElements = (type === 1) ?  $elements.length : $elements.length - 1, //adj for even distro of elements when not full circle
                            slice = 360 * type / numberOfElements;

                        $elements.each(function(i) {
                            var $self = $(this),
                                rotate = slice * i + start,
                                rotateReverse = rotate * -1;

                            $self.css({
                                'transform': 'rotate(' + rotate + 'deg) translate(' + radius + ') rotate(' + rotateReverse + 'deg)'
                            });
                        });
                    }
                }
            },
        });
        $('.js-next-slide').on('click', function (e) {
            console.log("next")
            fullpage_api.moveSectionDown();
        });

        $('.arrowUp').click(function(){
            fullpage_api.moveSectionUp();
        });

        $('.arrowDown').click(function(){
            fullpage_api.moveSectionDown();
        });
    });
    // $.fn.fullpage.parallax.init();
}
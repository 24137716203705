/*
 * Sliders
 *
 * Calling the Slick Slider
 */

import '../../../js/custom/slick/slick';

var slider_dots;
const defaultSettings = {
    arrows: true,
    infinite: true,
    pauseOnHover: true,
    autoplay: false
};

export default () => {

    $('.js-slider-publishing').each(function () {
        const $child_slider = $(this);
        slider_dots = Boolean($child_slider.attr('data-dots'));
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: slider_dots,
            autoplaySpeed: 1600,
            speed: 600,
            center: true,
            cssEase: 'ease-out',
            useTransform: true,
            centerMode: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            rows: 0,
            prevArrow: $child_slider.parent().find('.js-slider-pub-prev'),
            nextArrow: $child_slider.parent().find('.js-slider-pub-next'),
            responsive: [{
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    prevArrow: $child_slider.parent().find('.js-slider-pub-prev'),
                    nextArrow: $child_slider.parent().find('.js-slider-pub-next'),
                }
            }]
        });
        $child_slider.not('.slick-initialized').slick(child_settings);
    });
}
